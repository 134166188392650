/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// fontawesome
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min';

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.scss';

import $ from 'jquery';
import 'bootstrap';
import 'jquery-datetimepicker/build/jquery.datetimepicker.full.min';
import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import swal from 'sweetalert';
import 'toastr/build/toastr.min';
import Typewriter from 'typewriter-effect/dist/core';

window.$ = $;
window.jQuery = $;

import * as appointment from './app/appointment';
import * as message from './app/message';
import * as loading from './app/loading';
import * as Routing from './routing';

window.loading = loading;
window.appointment = appointment;
window.message = message;

window.initTypewriter = (messages) => {
    let msg = document.getElementById('main-msg');

    msg = new Typewriter(msg, {
        loop: false
    });

    messages.forEach((message, index) => {
        msg.typeString(message)
            .pauseFor(1000);

        if (index < messages.length - 1) {
            msg.deleteAll();
        }
    });

    msg.start();
};

$(document).ready(function () {

    // $(".group").click(function () {
    //
    //     var grp;
    //     $(this).attr("class").split(" ").forEach(myFNgrp);
    //
    //     function myFNgrp(item, index) {
    //         if (parseInt((item.search("group-"))) == 0) {
    //             grp = item;
    //             return true;
    //         }
    //     }
    //
    //     $("." + grp).removeClass("sel-group");
    //     $(this).addClass("sel-group");
    //
    // });

    // ---------------------------------------------------------
    // ---------------------------------------------------------
    // ---------------------------------------------------------

    $("#plus-cal").parent().click(function () {
        $("#cont-fecha-hora").animate({
            height: "toggle",
            opacity: 0.25
        }, 700, function () {
            $("#cont-per-fecha-hora").animate({
                height: "80px",
                opacity: 100
            }, 500);
        });

    });

    // ---------------------------------------------------------
    // ---------------------------------------------------------
    // ---------------------------------------------------------

    $("#page-contenido h1 i").click(function () {

        var act;
        var mov;
        $(this).attr("class").split(" ").forEach(myFNgrp);

        function myFNgrp(item, index) {
            if (parseInt((item.search("fa-arrow-"))) == 0) {
                act = item;
                return true;
            }
        }

        if (act == "fa-arrow-up")
            mov = $(this).parent().prevAll("h1");
        else
            mov = $(this).parent().nextAll("h1");

        $('html, body').animate({
            scrollTop: mov.offset().top,
        }, 500, 'linear')

    });

    // ---------------------------------------------------------
    // ---------------------------------------------------------
    // ---------------------------------------------------------

    $("#menu-informacion li a").click(function () {
        var tar;
        tar = $(this).attr("href");

        if (tar == "recursos.html") return true;

        tar = tar.replace(".html", "");

        $('html, body').animate({
            scrollTop: $("h1#" + tar).offset().top,
        }, 500, 'linear');

        return false;
    });


    // ---------------------------------------------------------
    // ---------------------------------------------------------
    // ---------------------------------------------------------


    $(window).scroll(function (event) {
        var scroll;
        scroll = $(window).scrollTop();
        scroll = scroll - parseInt($("#conte-header").css("height").replace("px", ""));

        if (scroll <= 0) return false;

        $("#page-sidebar").css("padding-top", scroll + "px");
    });

});