import swal from 'sweetalert';

export const initAppointmentForm = (successFn = () => void(0), failFn = () => void(0)) => {

    $(document).on('change', 'input#appointment_isMinor', (e) => {
        const responsibleData = $('#responsible-data');
        if ($('input#appointment_isMinor').is(':checked')) {
            responsibleData.removeClass('d-none');
        } else {
            responsibleData.addClass('d-none')
        }
    });

    $('.group').on('click', function () {
        const group = $(this);
        const value = group.data('value');
        const storeId = group.data('store-id');

        group.siblings('.group').removeClass('sel-group');
        group.addClass('sel-group');
        $(storeId).val(value);
    });

    $(document).ready(function () {
        $('.group').each(function (index, item) {
            const group = $(item);
            const value = group.data('value');
            const currentValue = group.data('current-value');

            if (value === currentValue) {
                group.addClass('sel-group');
            }
        });

        const responsibleData = $('#responsible-data');
        if ($('input#appointment_isMinor').is(':checked')) {
            responsibleData.removeClass('d-none');
        } else {
            responsibleData.addClass('d-none');
        }
    });

    /** Form validation */
    const validator = $('form[name="appointment"]').validate({
        rules: {
            'appointment[date]': {
                required: true,
            },
            'appointment[time]': {
                required: true,
            },
            'appointment[comment]': {
                required: true,
            },
            'appointment[consultation_type]': {
                required: true,
            },
            'appointment[through]': {
                required: true,
            },


            'appointment[fullName]': {
                required: true,
            },
            'appointment[address]': {
                required: true,
            },
            'appointment[phone]': {
                required: () => {
                    return !$('[name="appointment[cellphone]"]').val()
                },
            },
            'appointment[cellphone]': {
                required: () => {
                    return !$('[name="appointment[phone]"]').val()
                },
            },
            'appointment[email]': {
                required: true,
                email: true,
            },
            'appointment[bornDate]': {
                required: true,
            },
            'appointment[responsibleFullname]': {
                required: () => {
                    return $('[name="appointment[isMinor]"]').is(':checked')
                },
            },
            'appointment[responsibleCellphone]': {
                required: () => {
                    return $('[name="appointment[isMinor]"]').is(':checked')
                },
            },
            'appointment[responsibleAddress]': {
                required: () => {
                    return $('[name="appointment[isMinor]"]').is(':checked')
                },
            },
            'appointment[responsibleRelationship]': {
                required: () => {
                    return $('[name="appointment[isMinor]"]').is(':checked')
                },
            },

        },
        messages: {
            'appointment[date]': {
                required: "El campo Fecha es requerido.",
            },
            'appointment[time]': {
                required: "El campo Hora es requerido.",
            },
            'appointment[comment]': {
                required: "El campo Motivo de la consulta es requerido.",
            },
            'appointment[consultation_type]': {
                required: "El campo Tipo de consulta es requerido.",
            },
            'appointment[through]': {
                required: "El campo A través es requerido.",
            },

            'appointment[fullName]': {
                required: "El campo Nombre y Apellidos es requerido.",
            },
            'appointment[address]': {
                required: "El campo Dirección es requerido.",
            },
            'appointment[phone]': {
                required: "Debe especificar al menos un número de teléfono.",
            },
            'appointment[cellphone]': {
                required: "Debe especificar al menos un número de teléfono.",
            },
            'appointment[email]': {
                required: "El campo Email es requerido.",
                email: "Dirección de correo inválida."
            },
            'appointment[bornDate]': {
                required: "El campo Fecha de nacimiento es requerido.",
            },
            'appointment[responsibleFullname]': {
                required: 'El campo Nombre y apellidos del responsable es requerido.',
            },
            'appointment[responsibleCellphone]': {
                required: 'El campo Teléfono del responsable es requerido.',
            },
            'appointment[responsibleAddress]': {
                required: 'El campo Dirección del responsable es requerido.',
            },
            'appointment[responsibleRelationship]': {
                required: 'El campo Relación del responsable es requerido.',
            },
        },
        errorElement: "div",
        errorPlacement: function (error, element) {
            error.addClass("invalid-feedback");
            if (element.prop("type") === "checkbox") {
                error.insertAfter(element.next("label"));
            } else {
                error.insertAfter(element);
            }
        },
        highlight: function (element, errorClass, validClass) {
            $(element).addClass("is-invalid").removeClass("is-valid");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).addClass("is-valid").removeClass("is-invalid");
        },
        submitHandler: function (form) {
            event.preventDefault();
            loading.show();
            $.ajax({
                url: form.action,
                method: form.method,
                data: $(form).serialize(),
                dataType: "json",
            }).done((response) => {
                loading.hide();
                swal("Éxito", "Operación realizada correctamente.", "success")
                    .then(() => {
                        successFn();
                    });

                form = $(form);
                form[0].reset();
                form.find('.form-control').removeClass('is-invalid').removeClass('is-valid');

            }).fail((data, status, xhr) => {
                loading.hide();
                swal("Error", "No se pudo realizar la operación.\nVerifique los campos del formulario.", "error")
                    .then(() => {
                        failFn();
                    });

                const errorsObj = data.responseJSON.errors || {};
                let errors = {};
                Object.keys(errorsObj).forEach(key => {
                    errors[`appointment[${key}]`] = errorsObj[key] || [];
                });
                validator.showErrors(errors);

            }).always(() => {

            });
        }
    });

};

export const initPickers = (locale = 'es', minDate = null) => {

    $.datetimepicker.setLocale(locale);

    $("input#appointment_date").datetimepicker({
        autoclose: true,
        timepicker: false,
        format: 'd/m/Y',
        minDate: minDate,
        scrollMonth: false,
        scrollInput: false,
    });

    $("input#appointment_bornDate").datetimepicker({
        autoclose: true,
        timepicker: false,
        format: 'd/m/Y',
        scrollMonth: false,
        scrollInput: false,
    });

    $("input#appointment_time").datetimepicker({
        autoclose: true,
        datepicker: false,
        format: 'H:i',
        minTime: '8:00',
        maxTime: '18:00',
        scrollMonth: false,
        scrollInput: false,
        // allowTimes: [
        //     '8:00', '9:00', '10:00', '11:00', '12:00',
        //     '13:00', '14:00', '15:00', '16:00', '17:00', '18:00']
    });
};